import { Flex } from '@mantine/core'
import { useMemo } from 'react'
import { FrequentlyAskedQuestionsSection } from '~/components/marketing/FAQSection'
import { ServiceType } from '~/endpoints/model'

// eslint-disable-next-line import/no-cycle
import { Title } from './ServiceDetails'

const QuestionsSection = ({ serviceType }: { serviceType: ServiceType }) => {
    const questions = useMemo(
        () => [
            {
                question: 'How does a 1:1 consultation call work?',
                answer:
                    '1:1 consultation calls are blocks of time to dig into the expertise of one of our travel creators. Each creator has destination and travel style specialities, and offers calls for different lengths of time. At the time of the appointment, join the live 1:1 video call from the calendar invite you receive after checkout, ask your questions, and get personalized high-quality advice for your trip.',
                types: [ServiceType.consultation],
            },
            {
                question: 'How long are consultation calls?',
                answer:
                    'Most of our travel creators offer 1:1 video consultations ranging between 20 - 90 minutes. During the booking process, select the duration that makes sense for you. If you need more time at the end of the call, you can book a second consultation. Please be respectful of the creator’s time and don’t go over the time you’ve booked!',
                types: [ServiceType.consultation],
            },
            {
                question: 'Can I book more than one travel creator at the same time?',
                answer:
                    'Yes! In fact, many of our customers book multiple travel creators for help with various aspects of their trip. For example, you can have a consultation call with one creator, but then book another to plan and organize your itinerary.',
                types: [ServiceType.consultation, ServiceType.recs, ServiceType.itinerary],
            },
            {
                question: 'What timezone are the video consultations based in?',
                answer:
                    "All of the times presented are local to your time. Just be sure the correct timezone is selected. For example, if you're based in New York, the times will be presented in ET.",
                types: [ServiceType.consultation],
            },
            {
                question: 'Can I reschedule or cancel my consultation?',
                answer:
                    'Yes. You can reschedule calls at least 48 hours before the session. Email the creator directly to coordinate a reschedule that works for them. You will receive their email address after making the booking.',
                types: [ServiceType.consultation],
            },
            {
                question: 'Is there guarantee in case I am dissatisfied in the service?',
                answer:
                    "Yes! We're confident that you'll love working with your travel creator, but in the rare case that it did not meet your expectations, contact hello@thatch.co and we'll make things right.",
                types: [ServiceType.consultation, ServiceType.recs, ServiceType.itinerary],
            },
            {
                question: `What are Curated Recommendations?`,
                answer: `Curated recommendations are sets of places, activities, hotels, shops, restaurants, etc tailored to your specific travel style by a creator you select. They will be delivered as a private guide in Thatch, which can then be accessed on the web or in the Thatch iOS or Android apps.`,
                types: [ServiceType.recs],
            },
            {
                question: `Is there a map view of the information I’m given?`,
                answer: `Yes! Every set of recommendations comes with an interactive map that behaves like a Google Map - you can see your current location, tap on pins, see the creators information, and so on. `,
                types: [ServiceType.recs, ServiceType.itinerary],
            },
            {
                question: `Does the Thatch App work offline?`,
                answer: `No. If you open the guide you before you lose connectivity, you will be able to browse the information that has already loaded, but you won’t be able to open new pages`,
                types: [ServiceType.recs, ServiceType.itinerary],
            },
            {
                question: `What if I want a revision after I get my service? `,
                answer: `Custom itineraries specify the number of revisions in each package level. For Curated Recs, creators specify the number of revisions that are included in their service in their description. For either service, creators are often willing to add additional revisions for an additional fee.`,
                types: [ServiceType.recs, ServiceType.itinerary],
            },
            {
                question: `How do I get my curated recommendations or itinerary?`,
                answer: `The creator will email you a link to their work once they have finished it. The link will open an interactive guide built for you by that creator in Thatch. You can then ‘save’ the guide to your Library, and access it from any time on the web or in the Thatch iOS or Android apps. Note that the apps do not work offline.`,
                types: [ServiceType.recs, ServiceType.itinerary],
            },
            {
                question: `Why do I have to create an account to buy these services?`,
                answer: `In order to view the guides and itineraries that creators make for you, you need a Thatch account. Any custom work that is done for you will be able to be saved to your Library in Thatch, where you can access it on the web or on a mobile device.`,
                types: [ServiceType.consultation, ServiceType.recs, ServiceType.itinerary],
            },
            {
                question: `How quick is the turnaround time for my selected service?`,
                answer: `Each creator sets their own turnaround times. Check the details on the packages they offer for details.`,
                types: [ServiceType.recs, ServiceType.itinerary],
            },
            {
                question: `How do payments work for Custom Itineraries?`,
                answer: `At checkout, you will see the full amount for the service, and then l pay 50% deposit. When the creator finalizes the itinerary for you, they will send it to you with a paywall for the balance you owe. You’ll pay that amount, and be able to access the itinerary for life!`,
                types: [ServiceType.itinerary],
            },
            {
                question: `Do Thatch Guides work offline?`,
                answer: `For now, no - you’ll need access to data or wifi to load your guides.`,
                types: [ServiceType.recs, ServiceType.itinerary],
            },
        ],
        []
    )

    return (
        <Flex
            direction="column"
            gap={16}
        >
            <Title
                value="Common"
                italic="Questions"
            />
            <FrequentlyAskedQuestionsSection questions={questions.filter(({ types }) => types.includes(serviceType))} />
        </Flex>
    )
}

export default QuestionsSection
