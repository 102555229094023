import { Divider, Flex } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { FC } from 'react'

import type { ServicesForm } from '~/components/buyer/service-details/ServiceDetails'
import TextAreaWithCounter from '~/components/shared/TextAreaWithCounter'
import { Typography } from '~/components/shared/text/Typography'
import { Question } from '~/endpoints/model'

import HeaderTitle from './HeaderTitle'

interface AnswerQuestionsStepProps {
  form: UseFormReturnType<ServicesForm>
  questions: Question[]
}

const AnswerQuestionsStep: FC<AnswerQuestionsStepProps> = ({ form, questions }) => {
  const submittedWithError = form.errors.questions

  return (
    <Flex
      sx={{ flex: 1 }}
      mb={32}
      gap={40}
      direction="column"
    >
      <HeaderTitle
        title="Answer a few questions to"
        bigTitle={{ normal: 'Share your', italic: 'preferences' }}
      />

      <Divider />

      {questions.map((question, idx) => (
        <Flex
          key={`question-${idx}`}
          direction="column"
          gap={15}
        >
          <Flex
            align="end"
            justify="space-between"
          >
            <Typography variant="body3">
              {question.description}
              {question.required ? '*' : ''}
            </Typography>
            {submittedWithError && question.required && (
              <Typography
                color="appRed.0"
                variant="caption"
              >{`${submittedWithError}`}</Typography>
            )}
          </Flex>

          <TextAreaWithCounter
            form={form as any}
            formField={`questions.${idx}.answer`}
            minRows={2}
            hideCounter={true}
            {...form.getInputProps(`questions.${idx}.answer`)}
          />
        </Flex>
      ))}
    </Flex>
  )
}

export default AnswerQuestionsStep
