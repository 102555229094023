import { Box, Flex, Group } from '@mantine/core'

import { ThatchAvatar } from '~/components/shared/ThatchAvatarDynamic'
import { ThatchLink } from '~/components/shared/ThatchLink'
import { ThatchPill } from '~/components/shared/ThatchPill'
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { getImageUrl } from '~/components/shared/image/helpers'
import { ShowLessText } from '~/components/shared/text/ShowLessText'
import { ShowMoreText } from '~/components/shared/text/ShowMoreText'
import { TextReadMoreOrLess } from '~/components/shared/text/TextReadMoreOrLess'
import { Typography } from '~/components/shared/text/Typography'
import { Language, ProfileSummaryType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'

// eslint-disable-next-line import/no-cycle
import { Title } from './ServiceDetails'

const SocialMediaBtn = ({ icon, url }: { url: string | null; icon: SvgIconType }) => (
  <Flex
    w={32}
    h={32}
    bg="appPaper.0"
    align="center"
    justify="center"
    sx={{
      borderRadius: 500,
      cursor: !url ? 'default' : 'pointer',
      opacity: !url ? 0.3 : 1,
    }}
  >
    {url ? (
      <ThatchLink
        isOpenInNewWindow
        to={url ?? ''}
      >
        <Flex
          align="center"
          justify="center"
        >
          <SvgIcon type={icon} />
        </Flex>
      </ThatchLink>
    ) : (
      <SvgIcon type={icon} />
    )}
  </Flex>
)

const CapTitle = ({ value }: { value: string }) => (
  <Typography variant="eyebrow">{value}</Typography>
)

const Tags = ({ title, values }: { title: string; values: string[] }) => (
  <Flex
    direction="column"
    gap={24}
  >
    <CapTitle value={title} />
    <Group spacing={8}>
      {values.map(tag => (
        <ThatchPill
          key={`tag-${tag}`}
          type="item"
          label={tag}
          bg="appPaper.0"
          sx={{ border: 'none' }}
          variant="caption"
        />
      ))}
    </Group>
  </Flex>
)

interface SellerInfoSectionProps {
  user: ProfileSummaryType
  hideAvatarAndSocial?: boolean
  maw?: number
}

const SellerInfoSection = ({ user, maw, hideAvatarAndSocial }: SellerInfoSectionProps) => {
  const { isMobileScreen } = useScreenSize()

  return (
    <Flex
      gap={16}
      maw={maw}
      direction="column"
    >
      {!hideAvatarAndSocial && (
        <Flex
          mb={16}
          gap={28}
          direction={isMobileScreen ? 'column' : 'row'}
          align={isMobileScreen ? 'start' : 'center'}
          justify="space-between"
        >
          <Flex
            direction="row"
            align="center"
            gap={16}
          >
            <ThatchAvatar
              url={user?.sellerPhoto ? getImageUrl({ src: user.sellerPhoto, width: 80 }) : ''}
              desktopSize={80}
            />
            <Box>
              <Title value="Get to know" />
              <Title
                italic={user.name}
                italicSx={{ whiteSpace: undefined, lineClamp: 2 }}
              />
            </Box>
          </Flex>

          <Flex gap={11}>
            <SocialMediaBtn
              icon={SvgIconType.WEBSITE_ICON}
              url={user.website}
            />
            <SocialMediaBtn
              icon={SvgIconType.INSTAGRAM_ICON}
              url={user.instagram}
            />
            <SocialMediaBtn
              icon={SvgIconType.TIKTOK_ICON}
              url={user.tiktok}
            />
            <SocialMediaBtn
              icon={SvgIconType.YOUTUBE_ICON}
              url={user.youtube}
            />
          </Flex>
        </Flex>
      )}

      <CapTitle value="ABOUT" />

      <TextReadMoreOrLess
        id="bio"
        variant="body2"
        text={user.bio}
        readMore={<ShowMoreText />}
        readLess={<ShowLessText />}
        linesLimit={3}
        textSx={{ color: '#595959' }}
        enabled
      />

      <Flex
        mt={32}
        gap={32}
        direction="column"
      >
        <Tags
          title="LANGUAGES"
          values={
            user.languages?.map(
              ({ name, level }: Language) => `${name} (${level.toLowerCase()})`
            ) || []
          }
        />
        <Tags
          title="FOCUSES & SpecialtIes"
          values={user.travelTypes || []}
        />
        {!!user.locations?.length && (
          <Tags
            title="DESTINATIONS"
            values={user.locations
              .map(location => location?.name || '')
              .filter((value, index, array) => index === array.indexOf(value))}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default SellerInfoSection
