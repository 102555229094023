import { getISODay, parseISO } from 'date-fns'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'

import {
  AvailabilityViewModel,
  AvailableTimeViewModel,
} from '~/client/onsched/consumer/data-contracts'
import {
  ResourceHourViewModel,
  ResourceHoursViewModel,
  WeekDayViewModel,
} from '~/client/onsched/setup/data-contracts'
import { SelectField, TextInputField } from '~/components/form/types'

export type EmailType = 'Google' | 'Outlook'

export type QuestionModel = {
  description: string
  required: boolean
}

export type AvailableTime = {
  startTime: Time
  endTime: Time
}

export type DayOfWeek = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun'

export type Time =
  | 0
  | 30
  | 100
  | 130
  | 200
  | 230
  | 300
  | 330
  | 400
  | 430
  | 500
  | 530
  | 600
  | 630
  | 700
  | 730
  | 800
  | 830
  | 900
  | 930
  | 1000
  | 1030
  | 1100
  | 1130
  | 1200
  | 1230
  | 1300
  | 1330
  | 1400
  | 1430
  | 1500
  | 1530
  | 1600
  | 1630
  | 1700
  | 1730
  | 1800
  | 1830
  | 1900
  | 1930
  | 2000
  | 2030
  | 2100
  | 2130
  | 2200
  | 2230
  | 2300
  | 2330

export const dayLabels: Record<DayOfWeek, string> = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
}

export type Availability = AvailableTime | false
export type WeekAvailability = Record<DayOfWeek, Availability>

export type UpcomingDay = {
  day: DayOfWeek
  date: Date
  slots: AvailableTimeViewModel[]
}
export type UpcomingAvailability = UpcomingDay[]

export function isAvailable(value: AvailableTime | false): value is AvailableTime {
  return value !== false
}

export type PackageType = 'base' | 'standard' | 'premium'
export type PackageName = 'Standard' | 'Premium' | 'Luxe'
export const packageLabel: Record<PackageType, PackageName> = {
  base: 'Standard',
  standard: 'Premium',
  premium: 'Luxe',
}

export interface PackageField {
  price?: number | null
  delivery?: number | null
  recs?: number | null
  duration?: number | null
  selected: boolean
  service?: string | null
  type: PackageType
}

export interface SellerSetupForm {
  id: number
  description: string
  packages: Record<PackageType, PackageField>
  questions: QuestionModel[]
  timezone?: string | null
  availableTimes: WeekAvailability
}

export const price: TextInputField = {
  label: 'Price',
  formFields: {
    price: '',
  },
  parentFieldName: 'price',
  validator: () => null,
  handleSubmit: allValues => ({ price: allValues.price as string }),
}

export const duration: SelectField = {
  label: 'Duration',
  options: [
    { value: 20, label: '20 Minutes' },
    { value: 30, label: '30 Minutes' },
    { value: 40, label: '40 Minutes' },
    { value: 45, label: '45 Minutes' },
    { value: 60, label: '60 Minutes' },
    { value: 90, label: '90 Minutes' },
  ] as any,
  formFields: {
    duration: 20,
  },
  parentFieldName: 'duration',
  validator: () => null,
  handleSubmit: allValues => ({ duration: allValues.duration as string }),
}

export const delivery: SelectField = {
  label: 'Delivery',
  options: [
    { value: 1, label: '1 day' },
    { value: 2, label: '2 days' },
    { value: 3, label: '3 days' },
    { value: 4, label: '4 days' },
    { value: 5, label: '5 days' },
    { value: 6, label: '6 days' },
    { value: 7, label: '7 days' },
    { value: 10, label: '10 days' },
    { value: 14, label: '14 days' },
    { value: 21, label: '21 days' },
    { value: 30, label: '30 days' },
  ] as any,
  formFields: {
    delivery: 1,
  },
  parentFieldName: 'delivery',
  validator: () => null,
  handleSubmit: allValues => ({ delivery: allValues.delivery as string }),
}

export const recs: SelectField = {
  label: 'Recs',
  options: [
    { value: 10, label: '10 Recs' },
    { value: 20, label: '20 Recs' },
    { value: 25, label: '25 Recs' },
    { value: 30, label: '30 Recs' },
    { value: 35, label: '35 Recs' },
    { value: 40, label: '40 Recs' },
    { value: 45, label: '45 Recs' },
    { value: 50, label: '50 Recs' },
    { value: 60, label: '60 Recs' },
    { value: 70, label: '70 Recs' },
    { value: 80, label: '80 Recs' },
    { value: 90, label: '90 Recs' },
    { value: 100, label: '100 Recs' },
  ] as any,
  formFields: {
    recs: 10,
  },
  parentFieldName: 'recs',
  validator: () => null,
  handleSubmit: allValues => ({ recs: allValues.recs as string }),
}

export const timeOptions: Record<Time, string> = {
  0: '12:00 AM',
  30: '12:30 AM',
  100: '01:00 AM',
  130: '01:30 AM',
  200: '02:00 AM',
  230: '02:30 AM',
  300: '03:00 AM',
  330: '03:30 AM',
  400: '04:00 AM',
  430: '04:30 AM',
  500: '05:00 AM',
  530: '05:30 AM',
  600: '06:00 AM',
  630: '06:30 AM',
  700: '07:00 AM',
  730: '07:30 AM',
  800: '08:00 AM',
  830: '08:30 AM',
  900: '09:00 AM',
  930: '09:30 AM',
  1000: '10:00 AM',
  1030: '10:30 AM',
  1100: '11:00 AM',
  1130: '11:30 AM',
  1200: '12:00 PM',
  1230: '12:30 PM',
  1300: '01:00 PM',
  1330: '01:30 PM',
  1400: '02:00 PM',
  1430: '02:30 PM',
  1500: '03:00 PM',
  1530: '03:30 PM',
  1600: '04:00 PM',
  1630: '04:30 PM',
  1700: '05:00 PM',
  1730: '05:30 PM',
  1800: '06:00 PM',
  1830: '06:30 PM',
  1900: '07:00 PM',
  1930: '07:30 PM',
  2000: '08:00 PM',
  2030: '08:30 PM',
  2100: '09:00 PM',
  2130: '09:30 PM',
  2200: '10:00 PM',
  2230: '10:30 PM',
  2300: '11:00 PM',
  2330: '11:30 PM',
}

export const defaultSchedule: WeekAvailability = {
  mon: {
    startTime: 900,
    endTime: 1700,
  },
  tue: {
    startTime: 900,
    endTime: 1700,
  },
  wed: {
    startTime: 900,
    endTime: 1700,
  },
  thu: {
    startTime: 900,
    endTime: 1700,
  },
  fri: {
    startTime: 900,
    endTime: 1700,
  },
  sat: false,
  sun: false,
}

function fromWeekday(day: DayOfWeek, value?: WeekDayViewModel): Availability {
  if (!value) {
    return defaultSchedule[day]
  }

  if (value.startTime === 0 && value.endTime === 0) {
    return false
  }

  return {
    startTime: value.startTime as Time,
    endTime: value.endTime as Time,
  }
}

function toWeekday(day: DayOfWeek, value?: Availability): ResourceHourViewModel | undefined {
  if (typeof value === 'undefined') {
    return undefined
  }

  if (isAvailable(value)) {
    return {
      startTime: value.startTime as Time,
      endTime: value.endTime as Time,
    }
  } else {
    return { startTime: 0, endTime: 0 }
  }
}

export function scheduleFromOnsched(availability?: ResourceHoursViewModel): WeekAvailability {
  const weekdays = availability ?? {}
  const entries = Object.keys(weekdays).map(day => [
    day,
    fromWeekday(day as DayOfWeek, weekdays[day as keyof ResourceHoursViewModel]),
  ])
  return Object.fromEntries(entries)
}

export function scheduleToOnsched(availability?: WeekAvailability): ResourceHoursViewModel {
  const weekdays = availability ?? ({} as WeekAvailability)
  const entries = Object.keys(weekdays).map(day => [
    day,
    toWeekday(day as DayOfWeek, weekdays[day as DayOfWeek]),
  ])
  return Object.fromEntries(entries)
}

export function availabilityFromOnsched(
  availability?: AvailabilityViewModel
): UpcomingAvailability {
  const times = groupBy(sortBy(availability?.availableTimes ?? [], 'date'), 'date')

  const weekdays: UpcomingAvailability = []
  if (!availability || !availability.startDate) {
    return weekdays
  }

  for (const key of Object.keys(times)) {
    const date = parseISO(key)
    const day: UpcomingDay = {
      date,
      day: Object.keys(dayLabels)[getISODay(date)] as DayOfWeek,
      slots: times[key],
    }
    weekdays.push(day)
  }

  return weekdays
}
