import { Typography } from '~/components/shared/text/Typography'

export default function HeaderText({ value, italic }: { value: string; italic: string }) {
  return (
    <Typography
      variant="h3"
      sx={{ display: 'flex', gap: 8 }}
    >
      {value}
      {italic && (
        <Typography
          variant="h3Italic"
          sx={{ letterSpacing: '-0.216px' }}
        >
          {italic}
        </Typography>
      )}
    </Typography>
  )
}
