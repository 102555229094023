import pluralize from 'pluralize'

import IconRow from '~/components/buyer/service-details/IconRow'
import { SvgIconType } from '~/components/shared/image/SvgIcon'
import { ServiceTier, ServiceType } from '~/endpoints/model'

export default function TopIcons({
  serviceTier,
  serviceType,
  hideDelivery,
}: {
  serviceType: ServiceType
  serviceTier: ServiceTier
  hideDelivery?: boolean
}): JSX.Element {
  switch (serviceType) {
    case ServiceType.consultation:
      return (
        <IconRow
          icon={SvgIconType.CLOCK_ICON}
          label={`${serviceTier.duration} minute live video call`}
          bold
          blackText
        />
      )
    case ServiceType.itinerary:
      return hideDelivery ? (
        <></>
      ) : (
        <IconRow
          icon={SvgIconType.CLOCK_ICON}
          label={`${pluralize('day', serviceTier.delivery ?? undefined, true)} delivery`}
          bold
          blackText
        />
      )
    default:
      return (
        <>
          <IconRow
            icon={SvgIconType.RECS_ICON}
            label={`${serviceTier.recs} curated recs`}
            bold
            blackText
          />
          {!hideDelivery && (
            <IconRow
              icon={SvgIconType.CLOCK_ICON}
              label={`${pluralize('day', serviceTier.delivery ?? undefined, true)} delivery`}
              bold
              blackText
            />
          )}
        </>
      )
  }
}
