import { Flex } from '@mantine/core'

import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { Typography } from '~/components/shared/text/Typography'

export default function IconRow({
  icon,
  label,
  bold,
  blackText,
}: {
  icon: SvgIconType
  label: string
  bold?: boolean
  blackText?: boolean
}): JSX.Element {
  return (
    <Flex
      gap={8}
      align="center"
      opacity={icon === SvgIconType.CLOSE_ICON ? 0.25 : 1}
      h={24}
    >
      <SvgIcon
        fill="appBlack.0"
        type={icon}
      />
      <Typography
        variant={bold ? 'button_small' : 'body3'}
        color={blackText ? 'appBlack.0' : 'appPlaceholder.0'}
      >
        {label}
      </Typography>
    </Flex>
  )
}
