import { Flex } from '@mantine/core'
import { FC } from 'react'

import { Typography } from '~/components/shared/text/Typography'
import { useScreenSize } from '~/hooks'

interface HeaderTitleProps {
  title: string
  bigTitle: {
    normal: string
    italic: string
  }
}
const HeaderTitle: FC<HeaderTitleProps> = ({ title, bigTitle }) => {
  const { isMobileScreen } = useScreenSize()

  return (
    <Flex
      direction="column"
      align="center"
      mt={40}
      gap={8}
    >
      <Typography variant={isMobileScreen ? 'title2' : 'body2'}>{title}</Typography>
      <Flex gap={8}>
        <Typography variant={isMobileScreen ? 'h2' : 'h3'}>{bigTitle.normal}</Typography>
        <Typography variant={isMobileScreen ? 'h2Italic' : 'h3'}>{bigTitle.italic}</Typography>
      </Flex>
    </Flex>
  )
}

export default HeaderTitle
