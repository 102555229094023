import { Group, Stack } from '@mantine/core'

import { ShowLessText } from '~/components/shared/text/ShowLessText'
import { ShowMoreText } from '~/components/shared/text/ShowMoreText'
import { TextReadMoreOrLess } from '~/components/shared/text/TextReadMoreOrLess'
import { Typography } from '~/components/shared/text/Typography'
import { ServiceType, getServiceTitle } from '~/endpoints/model'

const AboutSection = ({
  name,
  description,
  serviceType,
}: {
  name: string
  description: string
  serviceType: ServiceType
}) => {
  return (
    <Stack>
      <Group spacing={6}>
        {`About ${name}’s`.split(' ').map((word, idx) => (
          <Typography
            key={idx}
            variant="titleSerif"
            sx={{ fontSize: '26px !important' }}
            lineClamp={2}
          >
            {word}
          </Typography>
        ))}

        {getServiceTitle(serviceType)
          .split(' ')
          .map((word, idx) => (
            <Typography
              key={idx}
              variant="titleSerifItalic"
              sx={{ fontSize: '26px !important', whiteSpace: 'nowrap' }}
            >
              {word}
            </Typography>
          ))}
      </Group>

      <TextReadMoreOrLess
        id="bio"
        variant="body2"
        text={description}
        readMore={<ShowMoreText />}
        readLess={<ShowLessText />}
        linesLimit={3}
        textSx={{ color: '#595959' }}
        enabled
      />
    </Stack>
  )
}

export default AboutSection
