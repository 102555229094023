import { Box, Divider, Flex, Tooltip } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { IconHelp } from '@tabler/icons'
import { format, parseISO } from 'date-fns'
import { FC, ReactNode, useMemo } from 'react'
import { useTimezoneSelect } from 'react-timezone-select'

import {
  getServicePackageBottomIcons,
  getServicePackageDescription,
} from '~/components/buyer/service-details/PackagesTabsSection'
import type { ServicesForm } from '~/components/buyer/service-details/ServiceDetails'
import TopIcons from '~/components/buyer/service-details/TopIcons'
import { PackageType, packageLabel } from '~/components/seller/service-setup/PackageFields'
import { timezones } from '~/components/shared/TimezoneSelector'
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { Typography } from '~/components/shared/text/Typography'
import { SellerService, ServiceTier, ServiceType } from '~/endpoints/model'
import { formatPrice } from '~/utils/helper'
import { Payment } from '~/utils/payments'
import { pluralize } from '~/utils/string'
import { getTimezone } from '~/utils/time'

import HeaderTitle from './HeaderTitle'

export const formatDate = (date: string) => format(parseISO(date), 'EEEE, MMMM do')

const Card = ({ title, children }: { title: string; children: ReactNode }) => (
  <Flex
    direction="column"
    gap={24}
    bg="appPaper.0"
    p="24px 16px"
  >
    <Typography
      variant="eyebrow"
      sx={{ letterSpacing: '1.28px' }}
    >
      {title}
    </Typography>

    {children}
  </Flex>
)

interface OrderSummaryStepProps {
  form: UseFormReturnType<ServicesForm>
  service: SellerService
  packageType: PackageType
  daysAmount: number
  payment: Payment
}

const OrderSummaryStep: FC<OrderSummaryStepProps> = ({ form, service, packageType, payment }) => {
  const { options: timezoneOpts } = useTimezoneSelect({ labelStyle: 'original', timezones })

  const timezoneName = useMemo(
    () => getTimezone(form.values.timezone, timezoneOpts)?.label,
    [form.values.timezone, timezoneOpts]
  )

  const isItinerary = service.type === ServiceType.itinerary

  const getDetailsCardTitle = () => {
    switch (service.type) {
      case ServiceType.consultation:
        return 'APPOINTMENT'
      default:
        return 'DELIVERY'
    }
  }
  const getDetailsCardIcon = () => {
    switch (service.type) {
      case ServiceType.consultation:
        return SvgIconType.CALENDAR_ICON
      default:
        return SvgIconType.CLOCK_ICON
    }
  }

  const getDetailsCardIconLabel = () => {
    switch (service.type) {
      case ServiceType.consultation:
        return form.values.scheduledTime?.date && form.values.scheduledTime?.date
          ? `${formatDate(form.values.scheduledTime.date)} at ${
              form.values.scheduledTime.displayTime?.toLowerCase() ?? ''
            }`
          : null
      default:
        return pluralize('day', service[packageType]?.delivery as number)
    }
  }

  return (
    <Flex
      sx={{ flex: 1 }}
      mb={32}
      gap={40}
      direction="column"
    >
      <HeaderTitle
        title="Review your order and"
        bigTitle={{ normal: 'Confirm', italic: 'details' }}
      />

      <Divider />

      <Card title="PACKAGE">
        <Flex
          direction="column"
          gap={8}
        >
          <Flex
            align="center"
            justify="space-between"
          >
            <Typography variant="title">{packageLabel[packageType]}</Typography>
            <Typography variant="title">${payment.subtotal}</Typography>
          </Flex>
          {isItinerary && (
            <Typography
              variant="body3"
              color="appPlaceholder.0"
            >
              {`${formatPrice(payment.price)} per day x ${pluralize('day', payment.quantity)}`}
            </Typography>
          )}
        </Flex>

        <TopIcons
          serviceType={service.type}
          serviceTier={service[packageType] as ServiceTier}
          hideDelivery
        />

        <Typography
          variant="body2"
          color="appPlaceholder.0"
        >
          {getServicePackageDescription(service.type, packageType)}
        </Typography>

        <Flex
          direction="column"
          gap={8}
        >
          {getServicePackageBottomIcons(service.type, packageType)}
        </Flex>
      </Card>

      <Card title={getDetailsCardTitle()}>
        <Flex
          direction="column"
          gap={16}
        >
          {service.type === ServiceType.consultation && (
            <Flex
              gap={8}
              align="center"
            >
              <SvgIcon
                fill="appPlaceholder.0"
                type={SvgIconType.CLOCK_ICON}
              />
              <Typography
                variant="button_small"
                color="appPlaceholder.0"
              >
                {timezoneName}
              </Typography>
            </Flex>
          )}
          <Flex
            gap={8}
            align="center"
          >
            <SvgIcon
              fill="appPlaceholder.0"
              type={getDetailsCardIcon()}
            />
            <Typography
              variant="button_small"
              color="appPlaceholder.0"
            >
              {getDetailsCardIconLabel()}
            </Typography>
          </Flex>
        </Flex>
      </Card>

      <Card title="SUMMARY">
        <Flex
          align="center"
          justify="space-between"
        >
          <Typography
            variant="body2"
            color="appPlaceholder.0"
          >
            {isItinerary
              ? `${formatPrice(payment.price)} x ${pluralize('day', payment.quantity)}`
              : 'Order amount'}
          </Typography>
          <Typography
            variant="body2"
            color="appPlaceholder.0"
          >
            {formatPrice(payment.subtotal)}
          </Typography>
        </Flex>

        <Flex
          align="center"
          justify="space-between"
        >
          <Typography
            variant="body2"
            color="appPlaceholder.0"
            sx={{ display: 'flex', alignItems: 'center', gap: 8 }}
          >
            Service fee
            <Tooltip
              multiline
              withArrow
              width={300}
              sx={{ padding: 10 }}
              label={
                <Typography variant="caption">
                  This helps us operate our platform and offer 24/7 customer support for your
                  orders.
                </Typography>
              }
            >
              <Box>
                <IconHelp
                  style={{ marginBottom: -2 }}
                  width={15}
                  height={15}
                />
              </Box>
            </Tooltip>
          </Typography>
          <Typography
            variant="body2"
            color="appPlaceholder.0"
          >
            {formatPrice(payment.fee)}
          </Typography>
        </Flex>

        {isItinerary && (
          <Flex
            align="center"
            justify="space-between"
          >
            <Typography
              variant="body2"
              color="appPlaceholder.0"
            >
              Total (USD)
            </Typography>
            <Typography
              variant="body2"
              color="appPlaceholder.0"
            >
              {formatPrice(payment.total)}
            </Typography>
          </Flex>
        )}

        <Divider />

        {isItinerary ? (
          <Flex
            direction="column"
            gap={16}
          >
            <Flex
              align="center"
              justify="space-between"
            >
              <Typography variant="body1">Due Now</Typography>
              <Typography variant="body1">{formatPrice(payment.due.total)}</Typography>
            </Flex>

            <Flex
              align="center"
              justify="space-between"
            >
              <Typography
                variant="body1"
                color="appPlaceholder.0"
              >
                Due in {service[packageType]?.delivery} Days
              </Typography>
              <Typography
                variant="body1"
                color="appPlaceholder.0"
              >
                {formatPrice(payment.due.outstanding)}
              </Typography>
            </Flex>

            <Flex
              gap={4}
              align="center"
              sx={{ marginTop: 16 }}
            >
              <IconHelp
                color="#595959"
                style={{ marginBottom: -2 }}
                width={15}
                height={15}
              />
              <Typography
                variant="body3"
                color="appPlaceholder.0"
              >
                Sellers require a 50% deposit to get started on your trip.
              </Typography>
            </Flex>
          </Flex>
        ) : (
          <Flex
            align="center"
            justify="space-between"
          >
            <Typography variant="title">Total</Typography>
            <Typography variant="title">{formatPrice(payment.due.total)}</Typography>
          </Flex>
        )}
      </Card>
    </Flex>
  )
}

export default OrderSummaryStep
