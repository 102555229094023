import { Box, Group, Rating } from '@mantine/core'

import { Typography } from '../shared/text/Typography'
import { StarEmpty, StarFilled } from './ReviewIcons'

export const ServicesReviewSummary = ({
  reviews,
  rating,
}: {
  reviews?: number | null
  rating?: number | null
}) => {
  if (rating == null || reviews == null || reviews == 0) {
    return <Box />
  }

  return (
    <Group
      align="center"
      spacing={0}
      dir="row"
      // gap={2}
    >
      {/* {stars} */}
      <Rating
        readOnly
        fractions={2}
        value={rating}
        emptySymbol={StarEmpty}
        fullSymbol={<StarFilled />}
      />
      <Typography
        mx={4}
        variant="caption"
      >
        {rating.toFixed(1)}
      </Typography>
      <Typography
        color="appDescription.0"
        variant="caption"
      >
        ({reviews})
      </Typography>
    </Group>
  )
}
