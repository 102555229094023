import { Divider, Flex, Group, Loader } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { format } from 'date-fns'
import { FC, Fragment } from 'react'

import { AvailableTimeViewModel } from '~/client/onsched/consumer/data-contracts'
import type { ServicesForm } from '~/components/buyer/service-details/ServiceDetails'
import HeaderText from '~/components/buyer/service-details/order-service-drawer/HeaderText'
import {
  PackageField,
  UpcomingDay,
  availabilityFromOnsched,
} from '~/components/seller/service-setup/PackageFields'
import TimezoneSelector from '~/components/shared/TimezoneSelector'
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { Typography } from '~/components/shared/text/Typography'
import { ProfileSummaryType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'
import { useOnschedAvailability } from '~/hooks/onsched'

const isDayEqual = (slot: AvailableTimeViewModel, selected?: AvailableTimeViewModel) => {
  return slot.time === selected?.time && slot.date === selected?.date
}

export const formatDate = (date: Date) => format(date, 'EEEE, MMMM do')

const DaySchedules = ({
  availability,
  scheduledTime,
  selectTimeSlot,
}: {
  availability: UpcomingDay
  scheduledTime?: AvailableTimeViewModel
  selectTimeSlot: (slot: AvailableTimeViewModel) => void
}) => {
  const { isMobileScreen } = useScreenSize()
  const slotsCount = availability.slots.length

  console.debug({ slots: availability.slots })

  return (
    <Flex
      direction="column"
      sx={{ alignSelf: 'flex-start' }}
      gap={40}
    >
      <Typography variant={isMobileScreen ? 'title' : 'body1'}>
        {formatDate(availability.date)}
      </Typography>
      <Group>
        {[
          ...availability.slots,
          ...(slotsCount % 3 === 1 ? [null, null] : slotsCount % 3 === 2 ? [null] : []),
        ].map((time: AvailableTimeViewModel | null, idx) => {
          const isSlotSelected = time !== null && isDayEqual(time, scheduledTime)
          return (
            <Flex
              key={`time-${idx}`}
              align="center"
              justify="center"
              gap={8}
              bg={isSlotSelected ? 'appBlack.0' : 'appWhite.0'}
              sx={{
                borderRadius: 47,
                border: '1px solid black',
                padding: isMobileScreen ? '0px 12px' : '10px 24px',
                height: 40,
                flex: 1,
                width: '100%',
                cursor: time !== null ? 'pointer' : 'default',
                ':hover': {
                  backgroundColor: isSlotSelected ? 'appBlack.0' : '#F3F2EF',
                },
                visibility: time === null ? 'hidden' : 'visible',
              }}
              onClick={() => time !== null && selectTimeSlot(time)}
            >
              <SvgIcon
                type={SvgIconType.CIRCLE_ICON}
                fill="appGreen.0"
              />
              <Typography
                variant={isMobileScreen ? 'caption' : 'body3'}
                sx={{ whiteSpace: 'nowrap' }}
                color={isSlotSelected ? 'appWhite.0' : 'appBlack.0'}
              >
                {time?.displayTime?.toLowerCase() ?? '<error>'}
              </Typography>
            </Flex>
          )
        })}
      </Group>
    </Flex>
  )
}

interface ScheduleStepProps {
  form: UseFormReturnType<ServicesForm>
  user: ProfileSummaryType
  selectedPackage: PackageField
}

const ScheduleStep: FC<ScheduleStepProps> = ({ form, user, selectedPackage }) => {
  const { isMobileScreen } = useScreenSize()
  const { data: onschedAvailability, isLoading: isLoadingOnsched } = useOnschedAvailability(
    selectedPackage.service as string,
    user.locationId as string,
    form.values.timezone
  )

  const availability = onschedAvailability ? availabilityFromOnsched(onschedAvailability) : []

  return (
    <Flex
      sx={{ padding: isMobileScreen ? '40px 0px 32px 0px' : '40px 24px 32px 24px' }}
      align="center"
      direction="column"
      gap={8}
    >
      <Typography variant="body2">Choose a time for your</Typography>
      <HeaderText
        value={`${selectedPackage.duration} minute`}
        italic="video call"
      />
      {isLoadingOnsched ? (
        <Loader
          color="appBlack.0"
          mt={16}
        />
      ) : (
        <>
          <TimezoneSelector
            sx={{
              marginTop: 8,
              borderRadius: 24,
              width: 300,
              border: 'none !important',
            }}
            value={form.values.timezone}
            styles={{
              input: {
                borderRadius: 24,
                backgroundColor: '#F3F2EF !important',
              },
            }}
            onChange={timezone => timezone && form.setValues({ timezone })}
          />
          <Divider
            my={40}
            w="100%"
          />
          {availability.map((dayAvailability, idx) => (
            <Fragment key={`date-${dayAvailability.date}`}>
              {!!idx && (
                <Divider
                  my={40}
                  w="100%"
                />
              )}
              <DaySchedules
                key={`date-${dayAvailability.date}`}
                availability={dayAvailability}
                scheduledTime={form.values.scheduledTime}
                selectTimeSlot={slot => form.setValues({ scheduledTime: slot })}
              />
            </Fragment>
          ))}
        </>
      )}
    </Flex>
  )
}

export default ScheduleStep
