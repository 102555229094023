import { Select, SelectProps } from '@mantine/core'
import { FC } from 'react'
import { allTimezones, useTimezoneSelect } from 'react-timezone-select'

import { getTimezone } from '~/utils/time'

import { SvgIcon, SvgIconType } from './image/SvgIcon'

export const timezones = {
  ...allTimezones,
  'Europe/Berlin': 'Frankfurt',
}

type TimezoneSelectorProps = Omit<
  SelectProps & {
    value: string
    onChange: (timezone: string | null) => void
  },
  'data'
>

const TimezoneSelector: FC<TimezoneSelectorProps> = ({ sx, styles, value, onChange }) => {
  const { options: timezoneOpts } = useTimezoneSelect({ labelStyle: 'original', timezones })

  const handleChange = (timezoneValue: string) => {
    onChange(getTimezone(timezoneValue, timezoneOpts)?.value)
  }

  return (
    <Select
      sx={{ width: 350, border: '1px solid rgba(0, 0, 0, 0.50)', ...sx }}
      styles={{
        input: { fontSize: 22 },
        item: { color: 'black !important', padding: '8px 24px' },
        rightSection: { pointerEvents: 'none' },
        ...styles,
      }}
      size="lg"
      value={getTimezone(value, timezoneOpts)?.value}
      onChange={handleChange}
      data={timezoneOpts}
      rightSection={
        <SvgIcon
          type={SvgIconType.ARROW_DOWN}
          width={10}
          height={5}
        />
      }
    />
  )
}

export default TimezoneSelector
