import { Box, Flex, Image, Stack } from '@mantine/core'
import { useCallback } from 'react'

import { ServicesReviewSummary } from '~/components/reviews/ServicesReviewSummary'
import SellerBadge from '~/components/seller/SellerBadge'
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { getImageUrl } from '~/components/shared/image/helpers'
import { Typography } from '~/components/shared/text/Typography'
import { ServiceType, ThatchProStatus } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'

interface ProfileCardSectionProps {
  name: string
  homeLocation?: string | null
  photoUrl?: string | null
  serviceType?: ServiceType
  photoWidth?: number
  photoHeight?: number
  proStatus?: ThatchProStatus
  reviews?: number | null
  rating?: number | null
}

const ProfileCardSection = ({
  name,
  serviceType,
  homeLocation,
  photoUrl,
  photoWidth,
  photoHeight,
  proStatus,
  reviews,
  rating,
}: ProfileCardSectionProps) => {
  const { isMobileScreen } = useScreenSize()

  const Title = useCallback(() => {
    switch (serviceType) {
      case ServiceType.consultation:
        return (
          <Box>
            <Typography variant="h3">Book a call</Typography>
            <Typography
              variant="h3Italic"
              sx={{ textOverflow: 'ellipsis' }}
              maw={isMobileScreen ? '100%' : 364}
            >
              with {name}
            </Typography>
          </Box>
        )
      case ServiceType.itinerary:
        return (
          <Box>
            <Typography
              variant="h3Italic"
              sx={{ textOverflow: 'hidden' }}
              maw={isMobileScreen ? '100%' : 364}
            >
              Hire {name}
            </Typography>
            <Typography variant="h3">to plan your trip</Typography>
          </Box>
        )
      case ServiceType.recs:
        return (
          <Box>
            <Flex gap={6}>
              <Typography variant="h3">Get</Typography>
              <Typography variant="h3Italic">curated recs</Typography>
            </Flex>
            <Typography
              variant="h3"
              sx={{ textOverflow: 'ellipsis' }}
              maw={isMobileScreen ? '100%' : 364}
            >
              by {name}
            </Typography>
          </Box>
        )
      default:
        return (
          <Box>
            <Typography variant="h3">About</Typography>
            <Typography
              variant="h3Italic"
              lineClamp={2}
              sx={{ textOverflow: 'ellipsis' }}
              maw={isMobileScreen ? '100%' : 364}
            >
              {name}
            </Typography>
          </Box>
        )
    }
  }, [])

  return (
    <Flex gap={isMobileScreen ? 16 : 32}>
      {photoUrl && (
        <Image
          alt="seller-photo"
          src={getImageUrl({ src: photoUrl, width: isMobileScreen ? 100 : photoWidth || 200 })}
          width={isMobileScreen ? 100 : photoWidth || 200}
          height={isMobileScreen ? 133 : photoHeight || 268}
        />
      )}
      <Stack
        spacing={isMobileScreen ? 16 : 20}
        justify="center"
      >
        <Flex
          direction="column"
          gap={16}
        >
          <SellerBadge type={proStatus} />
          <Title />
          <ServicesReviewSummary
            rating={rating}
            reviews={reviews}
          />
        </Flex>

        {(!rating || !reviews || reviews == 0) && homeLocation && (
          <Flex
            gap={4}
            align="center"
          >
            <SvgIcon type={SvgIconType.HOME_ICON_OUTLINE} />
            <Typography variant="tag">Lives in {homeLocation}</Typography>
          </Flex>
        )}

        {/* <Flex
          gap={3}
          align="center"
        >
          <Rating
            value={5}
            color="appOrange.0"
            readOnly
            size="xs"
          />
          <Typography variant="caption">5.0</Typography>
          <Typography
            variant="caption"
            color="appDescription.0"
          >
            (29)
          </Typography>
        </Flex> */}
      </Stack>
    </Flex>
  )
}

export default ProfileCardSection
