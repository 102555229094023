import { Stack, Sx, Textarea, TextareaProps } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'

import { typography } from '~/theme'

import { GenericFormValues } from '../form/types'
import { Typography } from './text/Typography'

const DEFAULT_MAX_CHARS = 2000

type TextAreaWithCounterProps<T> = {
  form: UseFormReturnType<T>
  formField: keyof T
  containerSx?: Sx
  hideCounter?: boolean
} & TextareaProps

export default function TextAreaWithCounter<T extends object = GenericFormValues>({
  form,
  formField,
  containerSx,
  hideCounter,
  ...props
}: TextAreaWithCounterProps<T>) {
  return (
    <Stack
      spacing={8}
      sx={containerSx}
      align="end"
    >
      <Textarea
        styles={{
          root: { border: '1px solid rgba(0, 0, 0, 0.4)' },
          input: { ...typography.body2, padding: '16px !important', letterSpacing: '-0.08px' },

          ...props.styles,
        }}
        sx={{ width: '100%', ...props.sx }}
        radius={0}
        minRows={4}
        maxLength={DEFAULT_MAX_CHARS}
        autosize
        {...props}
      />
      {!hideCounter && (
        <Typography
          variant="caption"
          color="appPlaceholder.0"
        >
          {(form.values[formField] as string)?.length || 0} / {props.maxLength || DEFAULT_MAX_CHARS}
        </Typography>
      )}
    </Stack>
  )
}
