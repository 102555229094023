export class Payment {
  readonly serviceFeeThreshold = 75.0

  readonly smallChargeFee = 2.5

  readonly serviceFeeRate = 5.5 / 100.0

  get subtotal(): number {
    return Payment.roundCents(this.quantity * this.price)
  }

  get fee(): number {
    const isSmall = this.subtotal < this.serviceFeeThreshold
    const fee = this.subtotal * this.serviceFeeRate + (isSmall ? this.smallChargeFee : 0)
    return Payment.roundCents(fee)
  }

  get total(): number {
    return Payment.roundCents(this.subtotal + this.fee)
  }

  get due() {
    const subtotal = Payment.roundCents(this.subtotal * this.deposit)
    const fee = Payment.roundCents(this.fee * this.deposit)
    const total = Payment.roundCents(subtotal + fee)
    const outstanding = this.total - total
    return { subtotal, fee, total, outstanding }
  }

  constructor(
    readonly price: number,
    readonly quantity: number,
    readonly deposit: number = 1
  ) {
    if (deposit < 0 || deposit > 1) {
      throw new Error(`Invalid deposit amount (must be between 0 and 1): ${deposit}`)
    }
  }

  private static roundCents(val: number): number {
    return Math.floor(val * 100) / 100
  }
}
