import { Flex } from '@mantine/core'
import { useCallback } from 'react'

import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { Typography } from '~/components/shared/text/Typography'
import { ServiceType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'

const StepCard = ({
  title,
  description,
  iconType,
  isMobileScreen,
}: {
  title: string
  description: string
  iconType: SvgIconType
  isMobileScreen: boolean
}) => (
  <Flex
    direction="column"
    p={isMobileScreen ? 24 : '48px 16px'}
    py={48}
    px={16}
    sx={{ borderRadius: 8, flex: 1 }}
    justify="center"
    align="center"
    bg="appPaper.0"
    gap={32}
    miw={isMobileScreen ? 200 : undefined}
  >
    <Flex
      align="center"
      justify="center"
      sx={{ borderRadius: 45, width: 72, height: 72, border: '0.75px solid #595959' }}
    >
      <SvgIcon
        type={iconType}
        width={48}
        height={48}
      />
    </Flex>

    <Flex
      direction="column"
      sx={{ textAlign: 'center' }}
      gap={8}
    >
      <Typography variant={isMobileScreen ? 'body1' : 'body2'}>{title}</Typography>
      <Typography
        variant="caption"
        color="appPlaceholder.0"
      >
        {description}
      </Typography>
    </Flex>
  </Flex>
)

const HowItWorksSection = ({ serviceType }: { serviceType: ServiceType }) => {
  const { isMobileScreen } = useScreenSize()

  const getTitle = useCallback(
    (step: 2 | 3) => {
      switch (serviceType) {
        case ServiceType.consultation:
          return step === 2 ? 'Book a time' : 'Get advice'
        case ServiceType.recs:
          return step === 2 ? 'Place your order' : 'Get custom recs'
        default:
          return step === 2 ? 'Place your order' : 'Get an itinerary'
      }
    },
    [serviceType]
  )

  const getDescription = useCallback(
    (step: 2 | 3) => {
      switch (serviceType) {
        case ServiceType.consultation:
          return step === 2
            ? 'Select a time that works for both you and your expert’s schedule.'
            : 'Join the 1-on-1 video call, ask questions, and get expert advice.'
        case ServiceType.recs:
          return step === 2
            ? 'Share your preferences so your expert can tailor their recs for your trip.'
            : 'Your expert will deliver a list of personalized recs handpicked for your trip.'
        default:
          return step === 2
            ? 'Share your preferences so your expert can plan  the perfect itinerary.'
            : 'Your expert will deliver a custom itinerary designed for your trip.'
      }
    },
    [serviceType]
  )

  const getIconType = useCallback(
    (step: 2 | 3) => {
      switch (serviceType) {
        case ServiceType.consultation:
          return step === 2 ? SvgIconType.CALENDAR_OUTLINED : SvgIconType.CAMERA_OUTLINED
        case ServiceType.recs:
          return step === 2 ? SvgIconType.EDIT_NOTE_ICON : SvgIconType.LOCATION_PIN_ICON
        default:
          return step === 2 ? SvgIconType.EDIT_NOTE_ICON : SvgIconType.MAP_ICON
      }
    },
    [serviceType]
  )

  return (
    <Flex
      gap={32}
      direction="column"
    >
      <Flex
        gap={6}
        sx={{ letterSpacing: '-0.104px' }}
      >
        <Typography
          variant="titleSerif"
          sx={isMobileScreen ? { fontSize: '26px !important' } : {}}
        >
          How it
        </Typography>
        <Typography
          variant="titleSerifItalic"
          sx={isMobileScreen ? { fontSize: '26px !important' } : {}}
        >
          works
        </Typography>
      </Flex>

      <Flex
        gap={16}
        sx={
          isMobileScreen
            ? {
                overflowX: 'scroll',
                width: 'calc(100vw - 32px)',
                paddingBottom: 16,
                marginBottom: -16,
              }
            : {}
        }
      >
        <StepCard
          title="Pick a package"
          description="Experts offer a few levels of service. Pick the one that fits your needs best."
          iconType={SvgIconType.PICK_ICON}
          isMobileScreen={isMobileScreen}
        />
        <StepCard
          title={getTitle(2)}
          description={getDescription(2)}
          iconType={getIconType(2)}
          isMobileScreen={isMobileScreen}
        />
        <StepCard
          title={getTitle(3)}
          description={getDescription(3)}
          iconType={getIconType(3)}
          isMobileScreen={isMobileScreen}
        />
      </Flex>
    </Flex>
  )
}

export default HowItWorksSection
